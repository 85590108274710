import { SearchParam } from '@common/SearchParam';
import { useMemo } from 'react';
import { Params, useParams, useSearchParams } from 'react-router-dom';

/**
 * Convert react router params to search param items (array of key-value objects)
 * @param params React router params
 * @param mapping Mapping from react router param to search param key
 * @returns An array of key-value objects
 */
export function paramsToSearchParams(params: Readonly<Params<string>>, mapping: Record<string, string>) {
  return Object.entries(mapping)
    .filter(([from, _to]) => !!params[from])
    .map(([from, to]) => ({ key: to, value: params[from] }));
}

/**
 * Convert react router params to search param items (array of key-value objects)
 * @param mapping Mapping from react router param to search param key
 * @returns An array of key-value objects
 */
export function useParamsToSearchParams(mapping: Record<string, string>) {
  const params = useParams();
  const [urlSearchParams] = useSearchParams();
  const paramItems = useMemo(() => paramsToSearchParams(params, mapping), [params, mapping]);
  const searchItems = useMemo(
    () =>
      Object.keys(mapping).reduce((res, cur) => {
        const searchValue = urlSearchParams.get(cur);
        if (searchValue) {
          return [...res, { key: cur, value: searchValue }];
        }
        return res;
      }, []),
    [params, mapping],
  );
  return [...paramItems, ...searchItems];
}
