import { Collection, collections } from '@assets/homepage';
import { MintActionType, useMintDispatch, useMintState } from '@context/mint/MintContext';
import { useUserHook } from '@hooks/useUserHook';
import { mainSuite } from '@services/ServiceFactory';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { SvsProvider } from '@storyverseco/svs-navbar';
import { ContractAddress } from '@storyverseco/svs-types';

import './MintBox.scss';
import { AnalyticsEventName } from '@services/analytics/AnalyticsEventName';
import { waitForMyToken } from '@common/ChainwatcherWaiter';
import { Spinner } from '@components/spinner/Spinner';
import { pipelineApiCall, PipelineEndpoint } from '@common/SvsRestApi';
import { MintBoxFootnote } from './MintBoxFootnote';
import { ChainwatcherTimeoutError } from '@errors';
import { debug } from '@common/LogWrapper';

const log = debug('app:components:MintBox');

const defaultAvatar = 'https://i.stack.imgur.com/34AD2.jpg';

const MAX_INPUT = 50;

enum MintStep {
  Idle,
  Signing,
  Minting,
  WaitingForToken,
  Done,
}

const buttonCopy: Record<MintStep, string> = {
  [MintStep.Idle]: 'Mint 0.0505 ETH',
  [MintStep.Signing]: 'Signing', //... elipsis added in component
  [MintStep.Minting]: 'Minting', //... elipsis added in component
  [MintStep.WaitingForToken]: 'Creating story', //... elipsis added in component
  [MintStep.Done]: 'Done!',
};

interface Props {
  onMintStart?: () => void;
  onMintSuccess: (tokenId: string) => void;
  onMintFailure: (
    err: Error,
    data: {
      ErrorName: string;
      ErrorMessage: string;
      Message: string;
    },
  ) => void;
  resetOnSuccess?: boolean;
  skipAwaitToken?: boolean;
}

export const MintBox = ({ onMintStart, onMintSuccess, onMintFailure, resetOnSuccess, skipAwaitToken }: Props) => {
  const mintState = useMintState();

  const { wallet } = useUserHook({ providerType: SvsProvider.WalletConnect });

  const mintDispatch = useMintDispatch();

  // Local state
  const [selectedAmount, setSelectedAmout] = useState<number>(1);

  const [isCustomAmount, setIsCustomAmount] = useState(false);

  const [customAmount, setCustomAmount] = useState(1);

  const [mintStep, setMintStep] = useState(MintStep.Idle);

  const [tokenQuantity, setTokenQuantity] = useState(1);

  const [storyToken, setStoryToken] = useState<string>();

  const [ethEstimate, setEthEstimate] = useState<number>();

  const onAmountClick = (amount: number) => () => {
    setIsCustomAmount(false);
    setSelectedAmout(amount);
    setTokenQuantity(amount);
  };

  const onCustomAmountClick = useCallback(() => {
    setSelectedAmout(undefined);
    setIsCustomAmount(true);
    mainSuite.analyticsService.track(AnalyticsEventName.ButtonPress, {
      buttonName: 'custom_token_amount',
    });
  }, []);

  const onMinusClick = useCallback(() => {
    if (customAmount <= 1) {
      return;
    }
    const value = customAmount - 1;
    setCustomAmount(value);
    setTokenQuantity(value);
  }, [customAmount]);

  const onPlusClick = useCallback(() => {
    if (customAmount >= MAX_INPUT) {
      setCustomAmount(MAX_INPUT);
      return;
    }
    const value = customAmount + 1;
    setCustomAmount(value);
    setTokenQuantity(value);
  }, [customAmount]);

  const onCustomAmountInputChange = useCallback((evt: ChangeEvent<HTMLInputElement>) => {
    const { value } = evt.target;

    const numVal = Number(value);

    // Add 'numVal > 50' to some config
    if (Number.isNaN(numVal) || numVal < 0 || numVal > MAX_INPUT) {
      return;
    }

    setCustomAmount(numVal);
    setTokenQuantity(numVal);
  }, []);

  const profileData = useMemo(() => collections[mintState.sale.saleId as Collection], [mintState.sale]);
  const profileFootnote = profileData?.footnote ?? '';

  // No point on using callback here since most of the states are deps
  const startMint = () => {
    if (mintStep !== MintStep.Idle) {
      return;
    }

    mainSuite.analyticsService.track(AnalyticsEventName.ButtonPress, {
      buttonName: 'story_mint',
    });

    // Should never get in here
    if (!wallet?.address) {
      throw new Error(`Cannot mint for 'anon' user.`);
    }

    if (onMintStart) {
      onMintStart();
    }

    setTokenQuantity(isCustomAmount ? customAmount : selectedAmount);
    setMintStep(MintStep.Signing);
  };

  // Handle signing the transaction
  useEffect(() => {
    if (mintStep !== MintStep.Signing) {
      return;
    }

    const sign = async () => {
      try {
        const response = await mainSuite.saleService.signForMint({
          saleId: mintState.sale.saleId,
          payload: {
            hotWallet: wallet.address,
            tokenQuantity,
          },
        });

        mintDispatch({
          type: MintActionType.UpdateMintAndSig,
          mintAndSig: {
            rawMessage: response.rawMessage,
            signature: response.signature,
          },
          platform: response.platform,
        });

        setMintStep(MintStep.Minting);
      } catch (e) {
        failMint(`Error: Failed to sign transaction`, e.message, {
          error: e.message,
        });
      }
    };
    sign();
  }, [mintStep, tokenQuantity, wallet, mintState.sale]);

  // Call minting
  useEffect(() => {
    if (mintStep !== MintStep.Minting) {
      return;
    }

    const {
      sale: { saleId },
      mintAndSig: { rawMessage, signature },
      platform,
    } = mintState;

    if (!saleId || !rawMessage || !signature || !platform) {
      throw new Error(`Cannot mint without one of 'saleId' | 'rawMessage' | 'signature' | 'platform'`);
    }

    const mint = async () => {
      try {
        await mainSuite.saleService.mintWithSignature({
          saleId,
          payload: {
            hotWallet: wallet.address,
            rawMessage,
            signature,
            platform,
          },
        });

        setMintStep(MintStep.WaitingForToken);
      } catch (e) {
        log('mint from useEffect error:', e);
        failMint(
          `Error: Failed to mint`,
          `Current wallet address: ${wallet.address}\nError name: ${e.name}\nMessage: ${e.message}\nmintAndSig rawMessage: ${rawMessage}\nSignature: ${signature}\nPlatform: ${platform}`,
          {
            error: e.message,
          },
        );
      }
    };
    mint();
  }, [mintStep, wallet, mintState]);

  // Wait for token before we mark as done
  useEffect(() => {
    if (mintStep !== MintStep.WaitingForToken) {
      return;
    }

    if (skipAwaitToken) {
      setMintStep(MintStep.Done);
      return;
    }

    const waitForToken = async () => {
      // Wait for chainwatcher to add the token to the wallet
      try {
        const tokenId = await waitForMyToken(mintState.sale.tokenContractAddress as ContractAddress);

        mainSuite.analyticsService.track(AnalyticsEventName.MintSuccess, {
          walletAddress: wallet.address,
          tokenId,
        });

        setStoryToken(tokenId);
        setMintStep(MintStep.Done);
      } catch (e) {
        log('waitForToken error:', e);
        if (ChainwatcherTimeoutError.isError(e)) {
          failMint(`Error: ChainwatcherTimeoutError`, e.message, {
            error: e.message,
          });
        } else {
          failMint(`Error: Failed to get token`, e.message, {
            error: e.message,
          });
        }
      }
    };
    waitForToken();
  }, [mintStep, wallet, mintState.sale, skipAwaitToken]);

  // On mint complete, fire callback
  useEffect(() => {
    if (mintStep !== MintStep.Done) {
      return;
    }
    onMintSuccess(storyToken);
    if (resetOnSuccess) {
      setMintStep(MintStep.Idle);
    }
  }, [mintStep, storyToken, resetOnSuccess]);

  const failMint = useCallback((message: string, rawMessage: string, { error }: { error: string }) => {
    mainSuite.analyticsService.track(AnalyticsEventName.MintError, {
      ErrorName: message,
      ErrorMessage: error,
      Message: rawMessage,
    });
    onMintFailure(new Error(message), {
      ErrorName: message,
      ErrorMessage: error,
      Message: rawMessage,
    });
    setTimeout(() => {
      setMintStep(MintStep.Idle);
    }, 500);
  }, []);

  // Fetch mint fee whenever we don't have it set
  useEffect(() => {
    const fetchEthEstimate = async () => {
      const response = await pipelineApiCall({
        method: 'GET',
        endpoint: PipelineEndpoint.GetMintFee,
      });
      setEthEstimate(response);
    };
    if (!ethEstimate) {
      fetchEthEstimate();
    }
  }, [ethEstimate]);

  // Timer to auto refetch mint fee
  useEffect(() => {
    const interval = setInterval(() => {
      setEthEstimate(undefined);
    }, 60000 * 5); // 5min
    () => {
      clearInterval(interval);
    };
  }, []);

  const ethCost = useMemo(
    () => (ethEstimate * (isCustomAmount ? customAmount : selectedAmount)).toFixed(5),
    [ethEstimate, isCustomAmount, customAmount, selectedAmount],
  );

  const mintBtnCopy = useMemo(() => {
    if (mintStep === MintStep.Idle) {
      if (!ethEstimate) {
        return <Spinner />;
      }
      // `Mint ≈ ${ethCost} ETH`
      return `Mint`;
    }
    return buttonCopy[mintStep];
  }, [mintStep, ethEstimate, ethCost]);

  return (
    <section className={`MintBox_Container`}>
      <h1 className="MintBox_Text Title">{mintState.sale?.mintCopy?.open?.mintBox?.title ?? mintState.sale?.saleName ?? 'Collectible Story'}</h1>
      <div>
        <div className="Col Profile">
          <div className="Row PicAndName">
            <img className="Picture" src={profileData?.avatar || defaultAvatar} />
            <h2 className="MintBox_Text Name">{profileData?.author || 'Author name'}</h2>
          </div>
          <p className="MintBox_Text ProfileFootnote">{profileFootnote}</p>
        </div>
      </div>
      <div className="Col AmountSelector">
        <p className="MintBox_Text AmountText">Select Amount</p>
        <div className="Row AmountBtns">
          <div className={`Box AmountBtn${selectedAmount === 1 ? '_selected' : ''}`} onClick={onAmountClick(1)}>
            1
          </div>
          <div className={`Box AmountBtn${selectedAmount === 5 ? '_selected' : ''}`} onClick={onAmountClick(5)}>
            5
          </div>
          <div className={`Box AmountBtn${selectedAmount === 10 ? '_selected' : ''}`} onClick={onAmountClick(10)}>
            10
          </div>
          <div className={`Box CustomAmountBtn AmountBtn${isCustomAmount ? '_selected' : ''}`} onClick={onCustomAmountClick}>
            Custom
          </div>
        </div>
        {isCustomAmount && (
          <>
            <div className="Row CustomAmountPicker">
              <div className={`Box AmountBtn`} onClick={onMinusClick}>
                -
              </div>
              <input className="CustomAmountInput" value={customAmount} onChange={onCustomAmountInputChange} />
              <div className={`Box AmountBtn`} onClick={onPlusClick}>
                +
              </div>
            </div>
            <p className="MintBox_Text AmountText MaxPerAddress">Max per transaction: {MAX_INPUT}</p>
          </>
        )}
        <div className="Row MintPrice">
          <p className="MintBox_Text AmountText">
            Free x{tokenQuantity} {tokenQuantity === 1 ? 'NFT' : 'NFTs'}
            <span>0.00 ETH</span>
          </p>
          <p className="MintBox_Text AmountText">
            Platform Fee <span>≈{ethCost} ETH</span>
          </p>
          <p className="MintBox_Text AmountText Total">
            Total <span>{ethCost} ETH</span>
          </p>
        </div>
      </div>
      <div className={`Row MintBtn ${mintStep === MintStep.Idle ? '' : 'loading disabled'}`} onClick={startMint}>
        {mintBtnCopy}
      </div>
      <MintBoxFootnote />
    </section>
  );
};
